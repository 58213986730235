/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from './actions';

export default (state, action) => {
	switch (action.type) {
		case ACTIONS.USER_DETAILS:
			return {
				...state,
				user_info: action.data,
			};

		case ACTIONS.LOGIN:
			return {
				...state,
				user_info: action.data,
			};
		case `${ACTIONS.SET_MAP_OPTIONS}`:
			return {
				...state,
				map_options: { ...state.map_options, lat: action.data.lat, lng: action.data.lng },
			};
		case `${ACTIONS.LIST_ALL_USERS}`: {
			return {
				...state,
				list: action.data.data,
				pagination: {
					limit: parseInt(action.data.limit),
					start: parseInt(action.data.start),
					total: parseInt(action.data.total),
					currentPage: parseInt(action.data.page),
					totalPages: Math.ceil(action.data.total / action.data.limit),
				},
			};
		}

		default:
			return state;
	}
};
