import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default function LoadingDialog(props) {
	const { setOpen, open, title, hasActions, handleSubmit, isStatic } = props;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={!isStatic && handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title ? title : ''}</DialogTitle>
				<DialogContent>{props.children ? props.children : ''}</DialogContent>
				{hasActions ? (
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							Cancel
						</Button>
						<Button onClick={handleSubmit} autoFocus color="primary">
							Ok
						</Button>
					</DialogActions>
				) : (
					<></>
				)}
			</Dialog>
		</div>
	);
}
