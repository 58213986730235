import React, { createContext, useCallback, useReducer } from 'react';
import userReduce from './reducers';
import * as Service from './services';
import * as ACTIONS from './actions';
import { FETCH_LIMIT } from '../../../constants';

const initialState = {
	user_info: {},
	list: [],
	pagination: { limit: FETCH_LIMIT, start: 0, total: 0, currentPage: 1, totalPages: 0 },
	map_options: { lat: '27.700769', lng: '85.300140', zoom: 12 },
};

export const UserContext = createContext(initialState);
export const UserContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReduce, initialState);

	function setMapOptions(options) {
		dispatch({ type: ACTIONS.SET_MAP_OPTIONS, data: options });
	}

	const listUser = useCallback(async query => {
		const res = await Service.listUser(query);
		dispatch({ type: ACTIONS.LIST_ALL_USERS, data: res });
		return res;
	}, []);

	const deleteUser = useCallback(async id => {
		return Service.deleteUser(id);
	}, []);

	const registerUser = async (payload, wallet, address, passcode) => {
		return new Promise((resolve, reject) => {
			Service.registerUser(payload, wallet, address, passcode)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const getUserDetails = useCallback(async payload => Service.getUserDetails(payload), []);

	const getUserDetailsByWallet = async payload => {
		return new Promise((resolve, reject) => {
			Service.getUserDetailsByWallet(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const getEnterpriseDetailsByName = async payload => {
		return new Promise((resolve, reject) => {
			Service.getEnterpriseDetailsByName(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateUser = useCallback(async params => Service.updateUser(params), []);
	const updateUserStatus = useCallback(async (id, payload) => Service.updateUserStatus(id, payload), []);
	const updateApproval = useCallback(async payload => Service.updateApproval(payload), []);

	const updateImage = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateImage(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateImageEnterprise = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateImageEnterprise(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const uploadImage = async base64 => {
		if (!base64) throw Error('Failed to get Image');
		return new Promise((resolve, reject) => {
			Service.uploadImage({ file: base64 })
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	function loginUsingMetamask(payload) {
		return new Promise((resolve, reject) => {
			Service.loginUsingMetamask(payload)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	const getRoles = async payload => {
		return new Promise((resolve, reject) => {
			Service.getRoles(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateRole = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateRole(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateProfile = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateProfile(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};
	const updateEnterpriseDetails = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateEnterpriseDetails(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const addUser = payload => {
		return Service.addUser(payload);
	};

	const getAppID = useCallback(() => {
		return Service.getAppID();
	}, []);

	return (
		<UserContext.Provider
			value={{
				list: state.list,
				pagination: state.pagination,
				map_options: state.map_options,
				addUser,
				listUser,
				dispatch,
				deleteUser,
				getUserDetails,
				updateUser,
				updateUserStatus,
				uploadImage,
				loginUsingMetamask,
				setMapOptions,
				updateApproval,
				registerUser,
				getRoles,
				updateRole,
				getUserDetailsByWallet,
				updateProfile,
				getAppID,
				updateImage,
				updateEnterpriseDetails,
				getEnterpriseDetailsByName,
				updateImageEnterprise,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
