import React from 'react';
import { ThemeConfig } from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ScrollToTop from 'src/global/ScrollToTop';
import { SnackbarProvider } from 'notistack';
import { UserContextProvider } from './modules/user/core/context';
import { AppContextProvider } from './modules/app/core/context';
import { WalletContextProvider } from './modules/wallet/core/context';

import { NotifyContextProvider } from './modules/notify/core/context';

import Main from './Main';
// import LoadingScreen from 'src/global/LoadingScreen';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
	return (
		<div>
			<Router history={history}>
				<AppContextProvider>
					<WalletContextProvider>
						<SnackbarProvider autoHideDuration={2000}>
							<NotifyContextProvider>
								<ThemeConfig>
									<UserContextProvider>
										<ScrollToTop />
										<Main />
									</UserContextProvider>
								</ThemeConfig>
							</NotifyContextProvider>
						</SnackbarProvider>
					</WalletContextProvider>
				</AppContextProvider>
			</Router>
		</div>
	);
}

export default App;
