import API from '../../../constants/api';
import axios from 'axios';
import NodeRSA from 'node-rsa';
import qs from 'query-string';
import { getAuthConfig } from '../../../utils';

// TODO: Remove this
const PUBLIC_KEY = 'abc';

export function uploadImage(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API.UPLOAD}`, payload)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
}
export const updateUserStatus = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;

	try {
		const config = {
			headers: {
				auth_signature,
				data_signature,
			},
		};

		const res = await axios.patch(`${API.USERS}/${id}/status`, rest, config);
		return res.data;
	} catch (err) {
		throw err;
	}
};
export const updateUser = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
				data_signature,
			},
		};

		const res = await axios.patch(`${API.USERS}/${id}`, rest, config);
		return res.data;
	} catch (err) {
		throw err;
	}
};
export const listUser = async query => {
	const { auth_signature, ...rest } = query;
	const config = getAuthConfig(auth_signature);
	const res = await axios.get(`${API.USERS}?${qs.stringify(rest)}`, config);
	return res.data;
};

export const getAppID = async () => {
	const res = await axios.get(`${API.USERS}/app`);
	return res.data;
};

export function registerUser(payload, encryptedWallet, address, passcode) {
	const key = PUBLIC_KEY.replace(/\\n/g, '\n');
	const publicKey = new NodeRSA(key);
	const msg = { wallet: encryptedWallet, passcode };
	const encrypted = publicKey.encrypt(msg, 'base64');
	const userPayload = {
		...payload,
		wallet_address: address,
		wallet_backup: encrypted,
	};
	return new Promise((resolve, reject) => {
		axios
			.post(`${API.USERS}/register`, userPayload)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
}

export const deleteUser = async payload => {
	const { auth_signature, id } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
			},
		};
		const res = await axios.delete(`${API.USERS}/${id}`, config);
		return res;
	} catch (err) {
		throw err;
	}
};
export const getUserDetails = async payload => {
	const { auth_signature, id } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
			},
		};
		const res = await axios.get(`${API.USERS}/${id}`, config);
		return res;
	} catch (err) {
		throw err;
	}
};

export const getUserDetailsByWallet = async payload => {
	const { auth_signature, address } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
			},
		};
		const res = await axios.get(`${API.USERS}/wallet/${address}`, config);
		return res;
	} catch (err) {
		throw err;
	}
};

export const getEnterpriseDetailsByName = async payload => {
	const { auth_signature, name } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
			},
		};
		const res = await axios.get(`${API.SETTINGS}/${name}`, config);
		return res;
	} catch (err) {
		throw err;
	}
};

export const updateProfile = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API.USERS}/${id}/profile`, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export const updateEnterpriseDetails = async payload => {
	const { auth_signature, data_signature, ...rest } = payload;
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.put(`${API.SETTINGS}`, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export function loginUsingMetamask(payload) {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API.USERS}/login/wallet`, payload)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
}

export const updateApproval = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;
	try {
		const config = {
			headers: {
				auth_signature,
				data_signature,
			},
		};
		const res = await axios.patch(`${API.USERS}/${id}/approval`, rest, config);
		return res;
	} catch (err) {
		throw err;
	}
};

export const getRoles = async payload => {
	const { auth_signature } = payload;
	const config = {
		headers: {
			auth_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.get(`${API.ROLES}`, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export const updateRole = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.put(`${API.USERS}/${id}/roles`, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export const updateImage = async payload => {
	const { auth_signature, data_signature, id, ...rest } = payload;
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API.USERS}/${id}/update-image`, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export const updateImageEnterprise = async payload => {
	const { auth_signature, data_signature, name, ...rest } = payload;
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return new Promise((resolve, reject) => {
		axios
			.patch(`${API.SETTINGS}/${name}/update-image-enterprise`, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
};

export function addUser(payload) {
	const { auth_signature, data_signature, ...rest } = payload;
	const config = getAuthConfig(auth_signature, data_signature);
	return new Promise((resolve, reject) => {
		axios
			.post(API.USERS, rest, config)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err?.response?.data);
			});
	});
}

// export async function getUserById(userId) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}`,
//     method: 'get',
//     headers: {
//       access_token
//     }
//   });
//   return res.data;
// }

// export async function updateUser(userId, data) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}`,
//     method: 'post',
//     headers: {
//       access_token
//     },
//     data
//   });
//   return res;
// }

// export async function changeUserStatus(userId, status) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}/status`,
//     method: 'patch',
//     headers: {
//       access_token
//     },
//     data: { status }
//   });
//   return res.data;
// }

// export function forgotPassword(payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/forgot_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err?.response?.data);
//       });
//   });
// }

// export function verifyToken(payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${API.USERS}/password_reset/${payload.token}`)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err?.response?.data);
//       });
//   });
// }

// export function resetPassword(id, payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/${id}/reset_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err?.response?.data);
//       });
//   });
// }

// export function changePassword(id, payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/${id}/change_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err?.response?.data);
//       });
//   });
// }
