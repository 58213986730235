import React, { createContext, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingDialog from 'src/global/LoadingDialog';
import { Grid, Typography } from '@material-ui/core';
const initialState = {};

export const NotifyContext = createContext(initialState);
export const NotifyContextProvider = ({ children }) => {
	// const [state] = useReducer(appReduce, initialState);

	const [modal, setModal] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const toggleLoading = () => setModal(prev => !prev);

	const notify = useCallback(
		(msg, variant) => {
			enqueueSnackbar(msg, {
				variant,
				anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
			});
		},
		[enqueueSnackbar],
	);
	const confirmBox = useCallback(async (props = { title: '', text: '' }) => {
		const { title, text } = props;
		const result = await Swal.fire({
			title: title ? title : 'Are you sure?',
			text: text ? text : 'Please confirm your descision.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
		});
		return result;
	}, []);
	return (
		<NotifyContext.Provider
			value={{
				confirmBox: confirmBox,
				notify,
				toggleLoading,
			}}
		>
			<LoadingDialog open={modal} setOpen={setModal} isStatic>
				<Grid container flexDirection="column" alignItems="center" sx={{ py: 2 }}>
					<Grid item xs={4} sx={{ mb: 1 }}>
						<CircularProgress color="primary" disableShrink />
					</Grid>
					<Typography>Operation being processed. Please Wait.</Typography>
				</Grid>
			</LoadingDialog>
			{children}
		</NotifyContext.Provider>
	);
};
