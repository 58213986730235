import React, { createContext, useEffect, useReducer } from 'react';
import * as Service from './services';
import ACTION from './actions';
import appReduce from './reducer';

const initialState = { org: null };

export const AppContext = createContext(initialState);
export const AppContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(appReduce, initialState);

	useEffect(() => {
		let subscription = false;
		async function fetchOrgName() {
			if (subscription) return;
			const res = await Service.getAppNameFromID();
			dispatch({ type: ACTION.GET_APP_Name, res });
		}
		fetchOrgName();

		return () => (subscription = true);
	}, []);

	return (
		<AppContext.Provider
			value={{
				org: state.org,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
